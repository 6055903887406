import React from 'react';
import ContentLoader from 'react-content-loader';

export default ({ size, ...rest }) => {
	let w = 90;
	let h = 25;

	switch (size) {
		case 'xs':
			{
				w = 70;
				h = 20;
			}
			break;
		case 'sm':
			{
				w = 80;
				h = 25;
			}
			break;
		case 'md':
			{
				w = 100;
				h = 30;
			}
			break;
		case 'lg':
			{
				w = 120;
				h = 30;
			}
			break;
	}

	return (
		<ContentLoader
			speed={1}
			width={w}
			height={h}
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb"
			{...rest}
		>
			<rect rx="3" ry="3" width={w} height={h} />
		</ContentLoader>
	);
};
