import useConfig from '_store/ConfigProvider';
import useLanguage from '_store/LanguageProvider';
import Button from 'common/Button';
import TextInput from 'common/TextInput';
// import Lines from 'components/Lines';
import React from 'react';

import { Instagram, YouTube, Facebook } from '../../../common/SVG';

export default () => {
  const { getString, loaded } = useLanguage();
  const { countries, activities } = useConfig();

  return (
    <div className="bg-bg_light">
      <div className="md:mx-20">
        <div className=" container relative px-3 md:p-0">
          {/* <Lines /> */}
          <div className="relative above-line pb-5 flex md:block">
            <div className=" md:w-full w-1/2 flex pt-4 md:pt-20 flex-col md:flex-row text-center">
              <div className="w-full footer-text md:w-1/4">
                <h3 className="text-text_cyan pr-6 footer-text-h3  text-left md:text-center text-xl md:text-3xl">
                  {getString('FOOTER_LEFT_TEXT')}
                </h3>
                <p className="font-oswald last-update text-left">
                  {/* {getString('LAST_UPDATE')} */}
                </p>
              </div>
              <div className="hidden md:block w-full md:w-1/4">
                <p className="font-poppins_bold pb-4">
                  {getString('COUNTRIES')}
                </p>
                {Object.values(countries)
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((c) => {
                    return (
                      <a
                        className="block mb-2"
                        href={c.getUrl()}
                        key={c.getName()}
                      >
                        {c.getName()}
                      </a>
                    );
                  })}
              </div>
              <div className="hidden md:block w-full md:w-1/4">
                <p className="font-poppins_bold pb-4">{getString('TOURS')}</p>
                {Object.values(activities).map((a) => {
                  return (
                    <a
                      className={`block mb-2 ${a.isUnderlined() ? 'underline' : ''
                        }`}
                      href={`/tours?activity_id=${a._id}`}
                      key={a._id}
                    >
                      {a.getName()}
                    </a>
                  );
                })}
              </div>
              <div className="hidden md:block w-full md:w-1/4">
                <p className="font-poppins_bold pb-4">{getString('ABOUT')}</p>
                <a className="block mb-2" href="/about#the-project">
                  {getString('FOOTER_THE_PROJECT')}
                </a>
                <a className="block mb-2" href="/about#news">
                  {getString('NEWS')}
                </a>
                <a className="block mb-2" href="/about#contact">
                  {getString('CONTACT')}
                </a>
                <a
                  className="block mb-2"
                  target="_blank"
                  href="https://blacksea-cbc.net/cookies-policy/"
                >
                  {getString('PRIVACY_POLICY')}
                </a>
              </div>
            </div>
            <div className="h-auto md:h-24 md:w-full w-1/2 flex font-poppins_semibold md:my-10 flex-col md:flex-row items-center text-center md:tex-left pt-4 md:pt-0 ">
              <div className="hidden md:block last-update w-full md:w-1/4 py-4 md:py-2">
                <p className="font-oswald ">
                  {getString('LAST_UPDATE')}
                </p>
              </div>
              <div className="hidden w-full md:w-1/4 py-4 md:py-2 md:flex justify-around md:justify-between md:pr-20 items-center">
                <a
                  className="block"
                  target="_blank"
                  href="https://www.facebook.com/BlackSeaBasin"
                >
                  <Facebook />
                </a>
                <a
                  className="block"
                  target="_blank"
                  href="https://www.instagram.com/blackseabasincbc/"
                >
                  <Instagram />
                </a>
                <a
                  className="block"
                  target="_blank"
                  href="https://www.youtube.com/channel/UCQ6JX-6VX1sl3ByUilU8OZg"
                >
                  <YouTube />
                </a>
              </div>
              <div className="flex items-center contact-footer bg-bg_dark md:h-full w-full md:w-1/4 ">
                <TextInput
                  icon="/assets/img/close-envelope.svg"
                  placeholder={loaded ? getString('EMAIL') : ''}
                />
              </div>
              <div className="w-full send md:w-1/4 md:h-full">
                <Button>{getString('BUTTON_SEND')}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
