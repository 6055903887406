import service from 'common/service';
import View from './View';

class Country {
	constructor(res) {
		Object.keys(res).map((key) => {
			this[key] = res[key];
		});
	}

	getName() {
		return this.name;
	}

	getId() {
		return this.id;
	}

	getUrl() {
		return '/country/' + this.getName() + '/' + this.getId();
	}

	getInfo() {
		if (Array.isArray(this.info)) {
			return this.info;
		}

		return [];
	}

	getViews() {
		if (this._views) return this._views;

		this._views = Array.from(this.views).reduce((_views, vi) => {
			const v = new View(vi);
			_views.push(v);
			return _views;
		}, []);

		return this._views;
	}
}

Country.service = {
	getAll: () => {
		return service
			.get('/countries')
			.then((res) => res.data)
			.then((data) => {
				if (!Array.isArray(data)) throw 'Error Getting Countries';

				return data.reduce((_countries, country) => {
					const c = new Country(country);
					_countries[c.getId()] = c;
					return _countries;
				}, {});
			});
	},
	getById: (id) => {
		return service.get(`/countries/${id}`).then((res) => res.data);
	},
};

export default Country;
