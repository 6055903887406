import getImage from 'common/getImage';
import service from 'common/service';

class Tour {
	constructor(res) {
		Object.keys(res).map((key) => {
			this[key] = res[key];
		});

		this.normalizeCountires();
		this.normalizeActivities();
	}

	getName() {
		return this.name;
	}

	getId() {
		return this.id;
	}

	getUrl() {
		return '/tours/' + this.getId();
	}

	getPrice() {
		return this.priceRange;
	}

	getImage() {
		return getImage(this.image);
	}

	getFile() {
		return this.tourFile ? getImage(this.tourFile) : '#';
	}

	getDuration() {
		return this.duration;
	}
	getCountries() {
		return this.countries;
	}

	normalizeActivities() {
		if (!Array.isArray(this.activities)) return (this.activities = {});

		this.activities = this.activities.reduce(function (acts, act) {
			acts[act.id] = act;
			return acts;
		}, {});
	}

	normalizeCountires() {
		if (!Array.isArray(this.countries)) return (this.normalized_countries = {});

		this.normalized_countries = this.countries.reduce(function (
			countries,
			country
		) {
			countries[country.id] = country;
			return countries;
		},
		{});
	}

	hasCountries(country_ids) {
		if (
			!Array.isArray(country_ids) ||
			typeof this.normalized_countries != 'object'
		)
			return;

		try {
			for (let i = 0; i < country_ids.length; i++) {
				if (!this.normalized_countries[country_ids[i]]) {
					return false;
				}
			}

			return true;
		} catch (err) {
			return false;
		}
	}

	hasActivities(activities) {
		if (!Array.isArray(activities)) return;

		try {
			for (let i = 0; i < activities.length; i++) {
				if (!this.activities[activities[i]]) {
					return false;
				}
			}

			return true;
		} catch (err) {
			return false;
		}
	}
}

Tour.service = {
	getAll: () => {
		return service
			.get('/tours')
			.then((res) => res.data)
			.then((data) => {
				if (!Array.isArray(data)) throw 'Error Getting Tours';

				return data.reduce((_tours, tour) => {
					const t = new Tour(tour);
					_tours[t.getId()] = t;
					return _tours;
				}, {});
			});
	},
};

export default Tour;
