import useLanguage from '_store/LanguageProvider';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Ul = styled.ul`
	list-style: none;
	display: flex;
	flex-flow: row nowrap;
	position: 'absolute';
	flex-flow: column nowrap;
	a {
		margin: 20px 0px;
		color: #fff;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	a.active div {
		height: 5px;
		background-color: #fff;
		width: 20px;
		margin-left: -10px;
		margin-right: 10px;
	}
`;
const MainDiv = styled.div`
	z-index: 219;
	position: 'absolute';
	display: flex;
	justify-content: flex-end;
	/* flex-direction: column;
	justify-content: space-between; */

	@media (min-width: 978px) {
		display: none;
	}
	@media (max-width: 977px) {
		background-color: #fefcf9cc;
		position: fixed;
		transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
		top: 0;
		right: 0;
		height: 100vh;
		width: 100%;
		/* padding-top: 4rem;
		padding-left: 10px; */
		/* padding-bottom: 20px; */
		transition: transform 0.3s ease-in-out;
	}
`;
const Div = styled.div`
	/* z-index: 219; */
	/* position: 'absolute'; */
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	/* @media (min-width: 978px) {
		display: none;
	} */
	@media (max-width: 977px) {
		background-color: #feae4a;
		position: fixed;
		/* transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')}; */
		top: 0;
		right: 0;
		height: 100vh;
		width: 300px;
		padding-top: 4rem;
		padding-left: 10px;
		padding-bottom: 20px;
		transition: transform 0.3s ease-in-out;
	}
`;
const Section = styled.div`
	width: 150px;
	color: #fff;
	font-size: 20px;
`;

const RightNav = ({ open, setOpen }) => {
	const { languages, changeLanguage, getString, language } = useLanguage();

	return (
		<MainDiv open={open} onClick={() => setOpen(false)}>
			<Div>
				<div>
					{Object.keys(languages).map((code) => {
						return (
							<li
								className="block px-5 burger-lang h-6"
								key={code}
								onClick={() => {
									changeLanguage(code);
									setOpen(false);
								}}
							>
								{languages[code]}
							</li>
						);
					})}
				</div>
				<Ul>
					<NavLink to="/" exact className="navlink" activeClassName="active">
						<div />
						{getString('HOMEPAGE')}
					</NavLink>
					<NavLink to="/countries" className="navlink" activeClassName="active">
						<div />
						{getString('COUNTRIES')}
					</NavLink>
					<NavLink to="/tours" className="navlink" activeClassName="active">
						<div />
						{getString('TOURS')}
					</NavLink>
					<NavLink to="/about" className="navlink" activeClassName="active">
						<div />
						{getString('ABOUT')}
					</NavLink>
					<NavLink
						to="/information"
						className="navlink"
						activeClassName="active"
					>
						<div />
						{getString('INFORMATION')}
					</NavLink>
				</Ul>
				{/* <Section className="font-oswald ">
					Common borders. common solutions.
				</Section> */}
			</Div>
		</MainDiv>
	);
};

export default RightNav;
