import React, { useState } from 'react';
import styled from 'styled-components';

import RightNav from './RightNav';

const StyledBurger = styled.div`
	width: 3rem;
	height: 4rem;
	position: fixed;
	top: 0px;
	right: 20px;
	z-index: 220;
	padding: 1rem;
	align-items: center;
	justify-content: center;
    display: none;
    position: 'absolute';
	@media (min-width: 1032px) {
		display: none;
	}
	@media (max-width: 1031px) {
		display: flex;
		justify-content: space-around;
		flex-flow: column nowrap;
		background-color: #feae4a;
	}
	div {
		width: 1.5rem;
		height: 0.25rem;
		background-color: ${({ open }) => (open ? '#fff' : '#fff')};
		border-radius: 10px;
		transform-origin: 1px;
		transition: all 0.3s linear;
		&:nth-child(1) {
			transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
		}
		/* &:nth-child(2) {
			transform: ${({ open }) => (open ? 'translateX(100%)' : 'translateX(0)')};
			opacity: ${({ open }) => (open ? 0 : 1)};
		} */
		&:nth-child(2) {
			transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
		}
	}
`;

const Burger = () => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<StyledBurger open={open} onClick={() => setOpen(!open)}>
				<div />
				<div />
				{/* <div /> */}
			</StyledBurger>
			<RightNav open={open} setOpen={setOpen} />
		</>
	);
};

export default Burger;
