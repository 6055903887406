import { MainLayout } from 'layouts';
import React, { lazy } from 'react';
import { Switch, Route } from 'react-router';

export const routes = [
	{
		path: '/',
		component: MainLayout,
		routes: [
			{
				path: '/',
				exact: true,
				component: lazy(() => import('pages/Home')),
			},
			{
				path: '/countries',
				exact: true,
				component: lazy(() => import('pages/Countries')),
			},
			{
				path: '/tours',
				exact: true,
				component: lazy(() => import('pages/Tours')),
			},
			{
				path: '/about',
				exact: true,
				component: lazy(() => import('pages/About')),
			},
			{
				path: '/Information',
				exact: true,
				component: lazy(() => import('pages/Information')),
			},
			{
				path: '/news/:id',
				exact: true,
				component: lazy(() => import('pages/SingleNews')),
			},
			{
				path: '/tours/:id',
				exact: true,
				component: lazy(() => import('pages/SingleTour')),
			},
			{
				path: '/country/:name/:id',
				exact: true,
				component: lazy(() => import('pages/SingleCountry')),
			},
			{
				path: '/country/:country_id/view/:id',
				exact: true,
				component: lazy(() => import('pages/SingleView')),
			},
			{
				component: lazy(() => import('pages/Errors/Error404')),
			},
		],
	},
];

export const renderRoutes = (routes, extraProps = {}, switchProps = {}) => {
	return (
		<Switch {...switchProps}>
			{routes.map((route, i) => (
				<Route
					key={route.key || i}
					path={route.path}
					exact={route.exact}
					strict={route.strict}
					render={(props) =>
						route.render ? (
							route.render({ ...props, ...extraProps, route })
						) : (
							<route.component {...props} {...extraProps} route={route} />
						)
					}
				/>
			))}
		</Switch>
	);
};
