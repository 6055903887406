import service from 'common/service';

class Language {
	constructor() {}
}

Language.service = {
	getStrings: () => {
		return service.get('/strings').then((res) => res.data);
	},
};

export default Language;
