import useConfig from '_store/ConfigProvider';
import useLanguage from '_store/LanguageProvider';
import { Info } from 'common/SVG';
import getImage from 'common/getImage';
import React from 'react';
import { NavLink } from 'react-router-dom';
import useHomePage from '../../../_store/HomePageProvider';

export default () => {
  const { languages, changeLanguage, getString, language } = useLanguage();
  const { logos } = useHomePage();
  const { activities, countries } = useConfig();

  return (
    <nav className="flex  items-center flex-wrap h-24 below-line ">
      <div className="w-1/2 md:w-1/4 flex flex-row justify-between h-full items-center">
        {Array.isArray(logos) &&
          logos.map((logo) => {
            return (
              <a
                className="block w-1/4 py-2 md:p-0 "
                href={logo.url}
                target={logo.target}
                key={logo.image}
              >
                <img
                  src={getImage(logo.image)}
                  alt="logo"
                  className="w-full "
                />
              </a>
            );
          })}
      </div>
      <ul className=" list-none md:flex flex-1 justify-around md:px-30 text-base nav nav-bar">
        <li className="nav-li">
          <NavLink to="/" exact className="navlink" activeClassName="active">
            {getString('HOMEPAGE')}
          </NavLink>
        </li>
        <li className="nav-li">
          <NavLink to="/about" className="navlink " activeClassName="active">
            {getString('ABOUT')}
          </NavLink>
          <ul className="sub-menu-ul">
            <li>
              <a className="block mb-2 a-border-bottom" href="/about#the-project">
                {getString('FOOTER_THE_PROJECT')}
              </a>
            </li>
            <li>
              <a className="block a-border-bottom mb-2" href="/about#news">
                {getString('NEWS')}
              </a>
            </li>
            <li>
              <a className="block mb-2 a-border-bottom" href="/about#contact">
                {getString('CONTACT')}
              </a>
            </li>
          </ul>
        </li>
        <li className="nav-li">
          <NavLink to="/countries" className="navlink" activeClassName="active">
            {getString('COUNTRIES')}
          </NavLink>
          <ul className="sub-menu-ul">
            {/* data.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0); */}
            {Object.values(countries).sort((a, b) => a.name.localeCompare(b.name)).map((c) => {
              return (
                <li>
                  <a className="block a-border-bottom mb-2" href={c.getUrl()} key={c.getName()}>
                    {c.getName()}
                  </a>
                </li>
              );
            })}
          </ul>
        </li>
        <li className="nav-li">
          <NavLink to="/tours" className="navlink " activeClassName="active">
            {getString('TOURS')}
          </NavLink>
          <ul className="sub-menu-ul">
            <li className="tours-parent">
              {Object.values(activities).filter(item => item._id === "612e0eb2e864c25b3e65eeb0").map((a) => {
                return (
                  <a
                    className={`block mb-2 a-border-bottom ${a.isUnderlined() ? 'underline' : ''}`}
                    href={`/tours`}
                    key={a._id}
                  >
                    {a.getName()}
                  </a>
                );
              })}
              <ul className="regional-tours-ul">
                {Object.values(activities).filter(item => item._id !== "612e0740e864c25b3e65ee56" && item._id !== "612e0eb2e864c25b3e65eeb0").map((a) => {
                  return (
                    <li className="regional-tours-li">
                      <a
                        className={`block mb-2 a-border-bottom ${a.isUnderlined() ? 'underline' : ''
                          }`}
                        href={`/tours?activity_id=${a._id}`}
                        key={a._id}
                      >
                        {a.getName()}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </li>
            <li>
              {Object.values(activities).filter(item => item._id === "612e0740e864c25b3e65ee56").map((a) => {
                return (
                  <a
                    className={`block mb-2 a-border-bottom ${a.isUnderlined() ? 'underline' : ''}`}
                    href={`/tours?activity_id=${a._id}`}
                    key={a._id}
                  >
                    {a.getName()}
                  </a>
                );
              })}
            </li>
          </ul>
        </li>
      </ul>
      <div className="w-1/2 md:w-1/4 flex justify-end h-full items-center nav-bar">
        <select
          className="py-3 bg-transparent outline-none uppercase mx-5 text-center language"
          onChange={(e) => changeLanguage(e.target.value)}
          value={language}
        >
          {Object.keys(languages).map((code) => {
            return (
              <option className="block px-5 h-12" key={code} value={code}>
                {languages[code]}
              </option>
            );
          })}
        </select>
        <div style={{ height: '100%' }}>
          <div style={{ height: 66 }}>
            <NavLink
              to="/information"
              className="flex items-center bg-text_orange h-full cursor-pointer justify-center"
            >
              <Info />
            </NavLink>
          </div>
          <div className="size">
            <div
              onClick={() => {
                document.body.style.zoom = 1;
              }}
            >
              <div className="decrease_increase">-</div>
            </div>
            <div
              onClick={() => {
                document.body.style.zoom = 1.1;
              }}
            >
              <div className="decrease_increase">+</div>
            </div>
          </div>
          <div className="text-base text-center"> Font</div>
        </div>
      </div>
    </nav >
  );
};
