import Language from '_store/objects/Language';
import service from 'common/service';
import Loader from 'components/Loader';
import React from 'react';
import { defaultLanguage } from 'consts';

const LanguageContext = React.createContext();

export default () => React.useContext(LanguageContext);

export const LanguageContextProvider = ({ children }) => {
	const [language, setLanguage] = React.useState(defaultLanguage);
	const [strings, setStrings] = React.useState({});
	const [loaded, setLoaded] = React.useState(false);

	const languages = {
		en: 'En',
		ka: 'ქა',
		ukr: 'ukr',
		rom: 'rom',
		tur: 'tur',
		bul: 'bul',
	};

	const changeLanguage = (code) => {
		if (languages[code] == undefined) return;

		setLanguage(code);
	};

	const getString = (code, loaderSize = 'sm') => {
		const _str = strings[code];

		if (typeof _str == 'string') return _str;

		if (loaderSize) return <Loader size={loaderSize} />;
	};

	React.useEffect(() => {
		service.setLanguage(language);
		if (Object.keys(strings).length > 0) setStrings({});
		setLoaded(false);

		Language.service.getStrings().then((strings) => {
			if (typeof strings == 'object') {
				setStrings(strings);
			}
			setLoaded(true);
		});
	}, [language]);

	return (
		<LanguageContext.Provider
			value={{
				language,
				languages,
				loaded,
				changeLanguage,
				getString,
			}}
		>
			{children}
		</LanguageContext.Provider>
	);
};
