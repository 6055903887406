import service from 'common/service';
import getImage from 'common/getImage';
import Country from './Country';

class View {
	constructor(res) {
		Object.keys(res).map((key) => {
			this[key] = res[key];
		});
	}

	getText() {
		return this.text;
	}

	getId() {
		return this.id;
	}

	getTitle() {
		return this.title;
	}

	getUrl(country) {
		if (country instanceof Country) {
			return '/country/' + country.getId() + '/view/' + this.getId();
		}

		return '/news/' + this.getId();
	}

	getImage() {
		if (Array.isArray(this.images) && this.images.length) {
			return getImage(this.images[0]);
		} else if (this.url) {
			return getImage(this.url);
		}
	}

	getImages() {
		if (Array.isArray(this.images)) {
			return this.images;
		} else if (this.image) {
			return [this.image];
		}
		return [];
	}

	getDescription() {
		return this.description;
	}
}

View.service = {
	getAll: () => {
		return service
			.get('/views')
			.then((res) => res.data)
			.then((data) => {
				if (!Array.isArray(data)) throw 'Error Getting News';

				return data.reduce((_views, vi) => {
					const v = new View(vi);
					_views[v.getId()] = v;
					return _views;
				}, {});
			});
	},
	getByCountryAndViewId: (country_id, view_id) => {
		return service
			.get(`/countries/${country_id}/views/${view_id}`)
			.then((res) => {
				if (typeof res === 'object') {
					return new View(res.data);
				}
			});
	},
};

export default View;
