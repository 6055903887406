import useLanguage from '_store/LanguageProvider';
import Country from '_store/objects/Country';
import Tour from '_store/objects/Tour';
import React, { createContext, useState, useContext, useEffect } from 'react';

import Activity from './objects/Activity';

const ConfigContext = createContext({});

export default () => useContext(ConfigContext);

export const ConfigContextProvider = ({ children }) => {
	const { language } = useLanguage();

	const [config, setConfig] = useState({
		countries: {},
		tours: {},
		activities: {},
	});

	useEffect(() => {
		setTimeout(() => {
			fetchConfigs();
		}, 500);
	}, [language]);

	const fetchConfigs = () => {
		Promise.all([
			Country.service.getAll(),
			Tour.service.getAll(),
			Activity.service.getAll(),
		]).then(([countries, tours, activities]) => {
			setConfig({
				...config,
				tours,
				countries,
				activities,
			});
		});
	};

	return (
		<ConfigContext.Provider
			value={{
				countries: config.countries,
				tours: config.tours,
				activities: config.activities,
			}}
		>
			{children}
		</ConfigContext.Provider>
	);
};
