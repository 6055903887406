import React from 'react';

export default ({ children, ...props }) => {
	return (
		<button
			className="outline-none w-full bg-text_cyan h-full p-4 text-white uppercase"
			{...props}
		>
			{children}
		</button>
	);
};
