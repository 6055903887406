import useLanguage from '_store/LanguageProvider';
import api from 'common/service';
import React, { createContext, useState, useContext, useEffect } from 'react';

const HomePageContext = createContext({});

export default () => useContext(HomePageContext);

export const HomePageContextProvider = ({ children }) => {
	const { language } = useLanguage();
	const [state, setState] = useState({});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			fetchHomePage();
		}, 500);
	}, [language]);

	const fetchHomePage = () => {
		setLoading(true);
		service
			.getHomePage()
			.then((data) => {
				setState(data);
			})
			.then(() => {
				setLoading(false);
			});
	};

	return (
		<HomePageContext.Provider
			value={{
				about: state.about,
				logos: state.logos,
				countrySlides: state.slides,
				topTours: state.topTours,
				loading,
			}}
		>
			{children}
		</HomePageContext.Provider>
	);
};

const service = {
	getHomePage: async () => {
		try {
			const res = await api.get('/homepage').then((data) => data.data);
			return res;
		} catch (err) {
			throw err;
		}
	},
};
