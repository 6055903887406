import React from 'react';
import useLanguage from '_store/LanguageProvider';

export default () => {
	const { getString } = useLanguage();

	return (
		<div className="footer-bottom flex items-center  justify-center above-line">
			<div className="container relative px-3 md:p-0 ">
				<div className="font-poppins text-white py-3">
					{getString('FOOTER_BOTTOM')}
				</div>
			</div>
		</div>
	);
};
