import { ConfigContextProvider } from '_store/ConfigProvider';
import { LanguageContextProvider } from '_store/LanguageProvider';
import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { routes, renderRoutes } from 'routes';
import FilterItms from 'FilterItms';

const App = () => {
  const [filterItems, setFilterItems] = useState([]);
  return (
    <FilterItms.Provider value={
      {
        filterItems,
        setFilterItems
      }
    }>
      <BrowserRouter>
        <LanguageContextProvider>
          <ConfigContextProvider>{renderRoutes(routes)}</ConfigContextProvider>
        </LanguageContextProvider>
      </BrowserRouter>
    </FilterItms.Provider>
  );
};

export default App;