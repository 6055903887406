import React from 'react';

export default (props) => {
	const { icon, ...rest } = props;

	return (
		<div className="flex items-center searchbar-search-div">
			{icon && <img src={icon} alt="" className="pr-5" />}
			<input
				type="text"
				className="custom-input w-full bg-bg_dark outline-none"
				{...rest}
			/>
		</div>
	);
};
