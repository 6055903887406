import service from 'common/service';

class Activity {
	constructor(res) {
		Object.keys(res).map((key) => {
			this[key] = res[key];
		});
	}

	getName() {
		return this.name;
	}

	getId() {
		return this.id;
	}

	getUrl() {
		return '/activity/' + this.getName();
	}

	isUnderlined() {
		return this.getId() == '5edcf8620fcf146a6b929ec6';
	}
}

Activity.service = {
	getAll: () => {
		return service
			.get('/activities')
			.then((res) => res.data)
			.then((data) => {
				if (!Array.isArray(data.activities)) throw 'Error Getting Activities';

				return data.activities.reduce((_activities, act) => {
					const ac = new Activity(act);
					_activities[ac.getId()] = ac;
					return _activities;
				}, {});
			});
	},
};

export default Activity;
