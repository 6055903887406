import axios from 'axios';
import { defaultLanguage, languageKey } from 'consts';

class Service {
	constructor() {
		this._ax = axios.create({
			baseURL:
				process.env.NODE_ENV === 'production'
					? '/api/'
					: 'https://cultourbsb.com/api/',
		});

		this.language = defaultLanguage;

		this.setLanguage(this.language);
	}

	get = (...params) => {
		return this._ax.get(...params);
	};

	post = (...params) => {
		return this._ax.post(...params);
	};

	setLanguage(code) {
		this.language = code;
		this._ax.defaults.headers.common['Accept-Language'] = this.language;
		localStorage.setItem(languageKey, code);
		return this;
	}
}

export default new Service();
