// import Lines from 'components/Lines';
import { HomePageContextProvider } from '_store/HomePageProvider';
import React, { Suspense } from 'react';
import { renderRoutes } from 'routes';

import { Footer, FooterBottom, Navbar } from './components';
import Burger from './components/Burger';

export default (props) => {
	const { route } = props;

	return (
		<div className="bg-bg_main min-h-screen ">
			<div className="md:mx-20">
				<div className="bg-bg_main min-h-screen container relative px-3 md:p-0">
					{/* <Lines /> */}
					<HomePageContextProvider>
						<Navbar />
					</HomePageContextProvider>
					<Burger />
					<Suspense fallback={<Loading />}>
						{renderRoutes(route.routes)}
					</Suspense>
				</div>
			</div>

			<Footer />

			<FooterBottom />
		</div>
	);
};

const Loading = () => {
	return <h1>Loading...</h1>;
};
